html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
 *:focus {
    outline: none !important;
}
button:focus, input:focus, textarea:focus {
    outline: none;
}
